export { default as wrapRootElement } from './src/state/reduxWrapper';
import 'mdn-polyfills/NodeList.prototype.forEach';

export function onRouteUpdate({ location, prevLocation }) {
  // Dont fire on first route
  if (prevLocation == null) return
  
  // Check if datalayer is available
  if (window.dataLayer) {
    let newPathName = location.pathname;
    let previousPathName = prevLocation ? prevLocation.pathname : null;
    let PageTitle = newPathName.replace(/\//g, '');
    let fullTitle = "";
    if (PageTitle == "") {
      fullTitle = "home page";
    } else {
      fullTitle = PageTitle + " page";
    }
    setTimeout(() => {    
      let pathTitle = document.title;
      // Push routechange 
      dataLayer.push({
        "event" : "custom.route.change",
        "route" : {
          "title" : pathTitle,
          "current" : newPathName,
          "previous" : previousPathName
        },
        "content" : {
          "category" : 'Route change',
          "type" : 'Page',
          "version" : '1.01'
        }
      });
    }, 500);
  }
}