// INIT STATE
const initialState = {
  menuOpen: false,
  browser: '',
  counts: {},
  showAlertbar: false,
  alertBarOffset: 0,
  alertBarDetails: {},
  pathName: ''
};

// ACTIONS
// export const example = exampleArg => ({ type: 'EXAMPLE', data: exampleArg });
export const setBrowser = browser => ({ type: 'SET_BROWSER', data: browser });
export const setPathName = pathname => ({ type: 'SET_PATHNAME', data: pathname });
export const handleMenuOpen = openClose => ({ type: 'HANDLE_MENU_OPEN', data: openClose });
export const setShowAlertBar = show => ({ type: 'SHOW_ALERT_BAR', data: show });
export const setAlertBarDetails = details => ({ type: 'ALERT_BAR_DETAILS', data: details });
export const setAlertBarOffset = offset => ({ type: 'ALERT_BAR_OFFSET', data: offset });


// REDUCER
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case 'SET_BROWSER':
      return { 
        ...state, 
        browser: data
      };
    case 'SET_PATHNAME':
      return { 
        ...state, 
        pathName: data
      };
    case `HANDLE_MENU_OPEN`:
      return {
        ...state, 
        menuOpen: data,
      };
    case `SHOW_ALERT_BAR`:
      return {
        ...state, 
        showAlertBar: data,
      };
    case `ALERT_BAR_DETAILS`:
      return {
        ...state, 
        alertBarDetails: data,
      };
    case `ALERT_BAR_OFFSET`:
      return {
        ...state, 
        alertBarOffset: data,
      };
    default:
      return state;
  }
};
